<template>
  <div>


    <div class="app-container">
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="3">
          <b>考核汇总</b>
        </el-col>

        <el-col :span="3">
          <el-button type="success" size="small" icon="el-icon-download"  @click="exportAll">导出考核汇总</el-button>
        </el-col>

        <!-- 年度选择框 -->
        <el-col :span="2" :pull="1" > 选择年度:  </el-col>
        <el-col :span="2" :pull="1">
          <el-select v-model="selectedYear" placeholder="请选择" @change="yearChange"  size="small">
            <el-option
              v-for="item in years"
              :key="item.id"
              :label="(item.startDate.substring(0,4)) + '年度'"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <!-- 律师名称搜索框  -->
        <el-col :span="3" :push="2" >律师名称:  </el-col>
        <el-col :span="4" :push="1">
          <el-input
            placeholder="请输入"
            v-model="searchLawyerName"
            size="small"
          >
          </el-input>
        </el-col>

        <!-- 搜索按钮 -->
        <el-col :span="6" :push="2">

          <el-button size="small" icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>
        </el-col>
      </el-row>

      <!-- 多选表单 -->
      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        ref="multipleTable"
        :data="tableData"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!--        <el-table-column-->
        <!--          type="selection"-->
        <!--          width="55">-->
        <!--        </el-table-column>-->

        <el-table-column
          align="center"
          prop="name"
          label="律师姓名"
          width="100">
        </el-table-column>

        <el-table-column
          prop="practiceno"
          align="center"
          label="执业证编号"
          width="200"
          >
        </el-table-column>


        <el-table-column
          align="center"
          prop="practicingYears"
          label="职业年限"
          width="100">
        </el-table-column>



        <el-table-column label="培训" align="center">
          <el-table-column
            align="center"
            prop="eightScore"
            label="职业道德学时"
            width="140">
          </el-table-column>

          <el-table-column
            align="center"
            prop="eightScoreStatus"
            label="达标状态"
            width="100">
          </el-table-column>

          <!--          <el-table-column-->
          <!--            align="center"-->
          <!--            prop="otherScore"-->
          <!--            label="导入培训学时"-->
          <!--            width="120">-->
          <!--          </el-table-column>-->


          <el-table-column
            align="center"
            prop="trainScore"
            label="其他培训学时"
            width="110">
          </el-table-column>


          <el-table-column
            align="center"
            prop="trainScoreStatus"
            label="达标状态"
            width="100">
          </el-table-column>

        </el-table-column>


        <el-table-column
          align="center"
          label="公益"
          width="150">


          <el-table-column
            align="center"
            prop="serviceScore"
            label="公益时长"
            width="100">
          </el-table-column>
          <el-table-column
            align="center"
            prop="serviceScoreStatus"
            label="达标状态"
            width="100">
          </el-table-column>

        </el-table-column>

        <el-table-column
          prop="phone"
          align="center"
          label="联系方式" width="180">
        </el-table-column>


        <el-table-column fixed="right" width="340" label="查看学时记录" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-more"
              @click="handleEightDetail(scope.row)"
            >职业道德</el-button
            >

            <el-button
              size="mini"
              type="info"
              icon="el-icon-more"
              @click="handleTrainDetail(scope.row)"
            >其他培训
            </el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-more"
              @click="handleServiceDetail(scope.row)"
            >公益学时
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>

    </div>
    <!--    8学时详情弹窗-->
    <el-dialog
      title="职业道德学时记录"
      align="center"
      :visible.sync="ifEightDetail"
      width="50%">

      <el-table
        max-height="400px"
        :data="eightRecord"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          width="50">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="日期"
          width="180"
          align="center">
        </el-table-column>

        <el-table-column
          prop="getWay"
          align="center"
          label="取得方式"
          width="200">
        </el-table-column>

        <el-table-column
          prop="detail"
          align="center"
          label="具体内容"
        >
        </el-table-column>

        <el-table-column
          prop="score"
          label="学时"
          width="180"
          align="center">
        </el-table-column>


      </el-table>
    </el-dialog>
    <!--    其他培训学时详情弹窗-->
    <el-dialog
      title="其他培训学时记录"
      align="center"
      :visible.sync="ifTrainDetail"
      width="50%">
      <el-table
        max-height="400px"
        :data="trainRecord"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          width="50">
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="日期"
          width="180"
          align="center">
        </el-table-column>

        <el-table-column
          prop="getWay"
          label="取得方式"
          align="center"
          width="200">
        </el-table-column>

        <el-table-column
          prop="detail"
          align="center"
          label="具体内容"
        >
        </el-table-column>

        <el-table-column
          prop="score"
          label="学时"
          width="180"

          align="center">
        </el-table-column>


      </el-table>
    </el-dialog>
    <!--    公益学时详情弹窗-->
    <el-dialog

      title="公益时长记录"
      align="center"
      :visible.sync="ifServiceDetail"
      width="60%">
      <el-table
        max-height="400px"
        :data="serviceRecord"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          width="50">
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="日期"
          width="180"
          align="center">
        </el-table-column>

        <el-table-column
          prop="getWay"
          label="取得方式"
          align="center"
          width="200">
        </el-table-column>

        <el-table-column
          prop="detail"
          label="具体内容"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="score"
          label="时长"
          width="180"
          align="center">
        </el-table-column>

      </el-table>
    </el-dialog>

    <el-dialog
      title="导出考核汇总"
      align="center"
      :visible.sync="ifExport"
      width="50%">
      <h1>共查询到{{json_data.length}}条记录</h1>

      <br>

      <download-excel
        class = "export-excel-wrapper"
        :data = "json_data"
        :fields = "json_fields"
        :name = "exportName">
        <!--          name = "律师考核汇总.xls">-->
        <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
        <el-button type="success" size="small" icon="el-icon-download" @click="ifExport=false">下载excel</el-button>
      </download-excel>

    </el-dialog>
  </div>
</template>

<script>
import { request } from '@/network/network'
import { transcript } from '@/utils/export'

export default {

  data() {
    return {
      ifExport: false,
      exportName:'',
      loading: true,
      // 加分记录
      eightRecord:[],
      trainRecord:[],
      serviceRecord:[],
      //导出excel绑定
      json_fields: transcript,
      json_data: [],

      total: 0,
      currentPage: 0,
      years:"",
      selectedYear:"1",


      tableData: [],
      currentData: "",

      ifEightDetail: false,
      ifTrainDetail: false,
      ifServiceDetail: false,

      searchLawyerName: '',
      searchMap: '',

      adminInformation: JSON.parse(sessionStorage.getItem("information"))


    }
  },
  methods: {
    exportAll(){
      var exportDate = new Date();
      var year = exportDate.getFullYear();
      var month = exportDate.getMonth() + 1;
      var day = exportDate.getDate();
      this.exportName = '律师考核汇总' + '(' + year + '-' + month+ '-' +day + ')' + '.xls'
      const loading = this.$loading({
        lock: true,
        text: '正在加载考核数据，请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.exportAssessments(this.selectedYear).then(
        res => {loading.close();
          this.ifExport = true
        })

    },
    //导出事件按钮
    handleExport(){

    },
    yearChange(){
      console.log("----")
      this.loading = true
      this.getAssessments(this.selectedYear)
    },

    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val;
      this.getAssessments(this.selectedYear, this.searchMap, val)
    },
    //搜索按钮
    handleSearch(){
      this.searchMap = this.searchLawyerName
      this.getAssessments(this.selectedYear, this.searchMap)
    },
    clearInput(){
      this.searchMap = ""
      this.searchLawyerName = ""
      this.getAssessments(this.selectedYear)

    },
    //多选改变事件
    handleSelectionChange(){
    },

    //查看8学时详情
    handleEightDetail(row) {
      this.ifEightDetail = true;
      this.getEightRecord(row.uid, this.selectedYear)
    },
    //查看其他培训学时详情
    handleTrainDetail(row) {
      this.ifTrainDetail = true;
      this.getTrainRecord(row.uid, this.selectedYear)
    },
    //查看公益学时详情
    handleServiceDetail(row) {
      this.ifServiceDetail = true;
      this.getServiceRecord(row.uid, this.selectedYear)
    },

    getYears() {
      return request({
        url: 'year/years',
        method: 'get',
        params: {}
      })
        .then(res=>{
          this.years = res.data
          let current = 0;

          for (let year of this.years) {
            if(year.id > current){
              current = year.id
            }
          }
          this.selectedYear = current

        })
    },
    getAssessments(yid, name, page) {
      return request({
        url: 'assessment/assessments',
        method: 'get',
        params: {
          yid,
          name,
          page,
          did: this.adminInformation.did
        }
      }).then(res => {
        this.total = res.data.total

        // console.log(res.data.list)

        let temp = res.data.list
        this.tableData = temp.map(item => {

          if(item.eightScore == null){item.eightScore = 0}
          if(item.aeightScore == null){item.aeightScore = 0}
          if(item.teightScore == null){item.teightScore = 0}

          if(item.trainScore == null){item.trainScore = 0}
          if(item.atrainScore == null){item.atrainScore = 0}
          if(item.otherScore == null){item.otherScore = 0}

          if(item.serviceScore == null){item.serviceScore = 0}

          item.trainScore = item.atrainScore + item.otherScore + item.trainScore

          item.eightScore = item.eightScore + item.aeightScore + item.teightScore

          // (item.serviceScore>=50)?item.serviceScoreStatus="达标":item.serviceScoreStatus="未达标";
          item.serviceScoreStatus= (item.serviceScore >= 50)? "达标":"未达标";

          // console.log(item.serviceScoreStatus)
          if(item.practicingYears <= 9){
            (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
            (item.eightScore + item.trainScore>=40)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
          }
          if(item.practicingYears >= 10 && item.practicingYears <=19){
            (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
            (item.eightScore + item.trainScore>=20)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
          }
          if(item.practicingYears >=20){
            (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
            item.trainScoreStatus="达标";
          }
          // console.log("1111---")
          return item
        })
        this.json_data = this.tableData
        this.loading = false
      })
    },
    exportAssessments(yid, did){
      return request({
        url: 'assessment/allAssessments',
        method: 'get',
        params: {
          yid,
          did: this.adminInformation.did
        }
      }).then(res => {
        let temp = res.data
        this.json_data = temp.map(item => {
          if(item.eightScore == null){item.eightScore = 0}
          if(item.aeightScore == null){item.aeightScore = 0}
          if(item.teightScore == null){item.teightScore = 0}

          if(item.trainScore == null){item.trainScore = 0}
          if(item.atrainScore == null){item.atrainScore = 0}
          if(item.otherScore == null){item.otherScore = 0}

          if(item.serviceScore == null){item.serviceScore = 0}

          item.trainScore = item.atrainScore + item.otherScore + item.trainScore

          item.eightScore = item.eightScore + item.aeightScore + item.teightScore

          // (item.serviceScore>=50)?item.serviceScoreStatus="达标":item.serviceScoreStatus="未达标";
          item.serviceScoreStatus= (item.serviceScore >= 50)? "达标":"未达标";

          // console.log(item.serviceScoreStatus)
          if(item.practicingYears <= 9){
            (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
            (item.eightScore + item.trainScore>=40)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
          }
          if(item.practicingYears >= 10 && item.practicingYears <=19){
            (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
            (item.eightScore + item.trainScore>=20)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
          }
          if(item.practicingYears >=20){
            (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
            item.trainScoreStatus="达标";
          }
          return item
        })

      })
    },
    //查职业道德记录
    getEightRecord(uid, yid){
      return request({
        url: 'assessment/eightScoreRecord',
        method: 'get',
        params: {
          uid,
          yid,
        }
      }).then(res => {
        this.eightRecord = res.data
        // console.log(this.eightRecord)

      })
    },
    //查其他培训记录
    getTrainRecord(uid, yid){
      return request({
        url: 'assessment/trainScoreRecord',
        method: 'get',
        params: {
          uid,
          yid,
        }
      }).then(res => {
        this.trainRecord = res.data
        // console.log(this.trainRecord)


      })

    },
    //查公益记录
    getServiceRecord(uid, yid){
      return request({
        url: 'assessment/serviceScoreRecord',
        method: 'get',
        params: {
          uid,
          yid,
          did: this.adminInformation.did
        }
      }).then(res => {
        this.serviceRecord = res.data
        // console.log(this.serviceRecord)

      })

    }
  },
  created() {

    this.getYears().then(res => {
      this.getAssessments(this.selectedYear)

    })
  }

}

</script>
